import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import HandymanIcon from '@mui/icons-material/Handyman';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NewappPage from '../pages/scriptCreator/newapp/newapp';
import DebugPage from '../pages/debugscript/debugscript';
import UploadCampaigns from '../pages/campaigns/uploadCampaigns/uploadCampaigns';
import DownloadCampaigns from '../pages/campaigns/downloadCampaigns/downloadCampaigns';
import AccountPermissionsPage from '../pages/accounts/accountPermissions';
import HomePage from '../pages/home/home';
import CampaignInfoPage from '../pages/campaigns/campaignInfo';
import DecrypterPage from '../pages/scriptCreator/decrypter/decrypter';
import DataDifference from '../pages/scriptCreator/datadifference/datadifference';
import ReceiptUpdater from '../pages/Tools/receiptUpdater/receiptUpdater';
import RetentionReport from '../pages/campaigns/retentionReport/retentionReport';
import MinorUpdate from '../pages/Tools/minorUpdate/minorUpdate';
import CT from '../pages/scriptCreator/ct/ct';
import UploadCampaign from '../pages/campaigns/uploadCampaign/uploadCampaign';
import UploadCampaignV2 from "../pages/campaigns/uploadCampaign/uploadCampaignV2";
import StintPage from '../pages/stint/stint';
import Level1 from '../pages/scriptCreator/level1/level1';
import Home2 from '../pages/home/home2';
import SingleLinkFire from '../pages/Tools/localFire/localFire';
import AdjApkUpload from '../pages/Tools/adjApkUpload/adjApkUpload';
import StintCustomizations, { isUserBTAM } from '../pages/stint/stintCustomizations/stintCustomizations';
import TimelineResource from '../pages/events/roomScehduler';
import RedirectToken from '../pages/redirectToken/redirectToken';
import IdsSimulation from '../pages/idssimulation/idssimulation';

export const SidebarData =  [
        {
            title :  "Home",
            icon  : <HomeIcon/>,
            subNav: [
                {
                    title :  "Home Page",
                    link  : "/home",
                    page : <HomePage/>,
                },
                // {
                //     title :  "Book Room",
                //     link  : "/event/bookroom",
                //     page : <TimelineResource/>,
                // },
                {
                    title :  "Stint (Task Tracker)",
                    link  : "/home/stint",
                    page : <StintPage/>,
                },

                {
                    title :  isUserBTAM() ? "Optimizations" : "Stint (Customizations)",
                    link  : "/home/stint/customizations",
                    page : <StintCustomizations/>,
                },
            ],
            line  : 0,
        },
        {
            title :  "Campaigns",
            icon  : <CloudSyncIcon/>,
            subNav: [
                {
                    title :  "Download Scripts",
                    link  : "/campaigns/download",
                    page : <DownloadCampaigns/>,
                },
                {
                    title :  "Newapps Upload",
                    link  : "/campaigns/upload",
                    page : <UploadCampaigns/>,
                },
                {
                    title :  "Upload Script (OLD)",
                    link  : "/campaigns/uploadScript",
                    page : <UploadCampaign/>,
                },
                {
                    title :  "Upload Script (V2)",
                    link  : "/campaigns/v2/uploadScript",
                    page : <UploadCampaignV2/>,
                },
                {
                    title :  "KPI Check",
                    link  : "/campaigns/kpicheck",
                    page : <DebugPage/>,
                    // page  : AllOffers,
                },
                {
                    title :  "Campaign Data Info",
                    link  : "/campaigns/campaignInfo",
                    page : <CampaignInfoPage/>,
                },
                {
                    title :  "Campaign Retention Report",
                    link  : "/campaigns/retentionreport",
                    page : <RetentionReport/>,
                },
            ]
        },
        {
            title :  "Script Creator",
            icon  : <PrecisionManufacturingIcon />,
            line  : 4,
            subNav: [
                {
                    title :  "New App",
                    link  : "/scriptcreator/newapp",
                    page : <NewappPage/>,
                    // page  : AllOffers,
                },
                {
                    title :  "Decrypter",
                    link  : "/scriptcreator/decrypter",
                    page : <DecrypterPage/>,
                    // page  : AllOffers,
                },
                {
                    title :  "Level 1 Creator",
                    link  : "/scriptcreator/ct",
                    page : <CT/>,
                    // page  : AllOffers,

                },
                {
                    title :  "Level 1 (Incomplete Project)",
                    link  : "/scriptcreator/level1",
                    page : <Level1/>,
                },
                {
                    title :  "Data Difference",
                    link  : "/scriptcreator/datadifference",
                    // page : ()=>{return <div>hel</div>},
                    page : <DataDifference/>,
                    // page  : AllOffers,
                },    
            ] 
        },
        {
            title :  "Tools",
            icon  : <HandymanIcon />,
            line  : 12,
            subNav: [
                {
                    title :  "Minor Update",
                    link  : "/tools/minorupdate",
                    page : <MinorUpdate/>,
                },
                {
                    title :  "IOS Receipt Updater",
                    link  : "/tools/receiptupdater",
                    page : <ReceiptUpdater/>,
                },
                {
                    title :  "Debug Script",
                    link  : "/tools/debugscript",
                    page : <DebugPage/>,
                },
                {
                    title :  "Local Fire",
                    link  : "/tools/localfire",
                    page : <SingleLinkFire/>,
                },
                {
                    title :  "Upload ADJ Apk",
                    link  : "/tools/adj_apk_upload",
                    page : <AdjApkUpload/>,
                },
                {
                    title :  "IDs Simulation",
                    link  : "/tools/ids_simulation",
                    page : <IdsSimulation/>,
                },
            ]
        },
        {
            title :  "Accounts",
            icon  : <ManageAccountsIcon />,
            subNav: [
                {
                    title :  "Account Settings",
                    link  : "/accounts/settings",
                    page : ()=>{return <div>hel</div>},
                    // page  : DateStatistics,
                },
                {
                    title :  "Account Permissions",
                    link  : "/accounts/permissions",
                    page : <AccountPermissionsPage/>,
                    // page  : OfferStatistics,
                },
            ]
        },
]


export const HelperTabs = [
    
    {
        title :  "profile",
        link  : "/account/profile",
        // page  : Profile,
    },
    {
        title :  "redirect",
        link  : "/redirect/token",
        page  : RedirectToken,
    }
]